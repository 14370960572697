import { useState, useEffect } from 'react'
import logo from './logo.svg'
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import Mapbox from './stork/map'
import Grid from './stork/grid'
import Intro from './stork/intro'

import './stork/main.css'
import { ReactComponent as Logo } from './media/imgs/logo.svg'
import { ReactComponent as Pitl } from './media/imgs/pitl.svg'

const App = () => {
  const [theme, setTheme] = useState({
    lit0: '#FFF9ED',
    lit1: '#FEF6E4',
    lit2: '#F3D2C1',
    blu1: '#D6EAFF',
    blu2: '#85C0FF',
    blu3: '#003366',
    blu4: '#001E3D',
    pnk1: '#F582AE',
    ylw0: '#FFFCEB',
    ylw1: '#FFF9D6',
    ylw2: '#FFED85',
  })
  useEffect(() => {
    document.body.style.backgroundColor = theme.ylw1
  }, [])
  return (
    <>
      <header>
        <Logo />
      </header>
      <main style={{ backgroundColor: theme.ylw1 }}>
        <Intro theme={theme} />

        <Grid theme={theme} />
        <div
          className='instruct'
          style={{ color: theme.blu3, marginTop: '100px' }}
        >
          If you are a K-12 teacher in the United States, signup{' '}
          <a
            href='https://docs.google.com/forms/d/e/1FAIpQLSfdpt1sG6XSTFeSSO4zLobIqNGxg5SzP8xT6zepmeoGomAhHA/viewform?usp=sf_link'
            target='_blank'
          >
            here
          </a>
          &nbsp;to allow students in your class to send their own composed
          messages and read replies.
        </div>
        <div className='instruct' style={{ color: theme.blu3 }}>
          An eighth grader in Massachusetts originated the idea of StorkSong to
          support Ukrainian people. The Tech Lab is happy to facilitate the
          service.
        </div>
        <footer>
          <Pitl />
        </footer>
      </main>
    </>
  )
}

export default App
