import { useState, useRef } from 'react'
import anime from 'animejs/lib/anime'
import './intro.css'
import { ReactComponent as Birds } from '../media/imgs/birds.svg'

const Intro = ({ theme }) => {
  return (
    <>
      <div className='intro'>
        <Birds />
        <div className='desc' style={{ color: theme.blu3 }}>
          A public space
          <br />
          to send messages of support
          <br />
          to Ukrainians
        </div>
      </div>
      <div className='instructbox'>
        <div className='instruct' style={{ color: theme.blu3 }}>
          Click&nbsp;
          <svg
            height='24'
            viewBox='0 0 24 24'
            width='24'
            style={{ transform: 'translateY(4px)' }}
          >
            <line
              fill='none'
              stroke={theme.blu2}
              stroke-linejoin='round'
              stroke-width='2'
              x1='22'
              x2='9.218'
              y1='3'
              y2='10.083'
            ></line>
            <polygon
              fill='none'
              points='11.698 20.334 22 3.001 2 3.001 9.218 10.084 11.698 20.334'
              stroke={theme.blu2}
              stroke-linejoin='round'
              stroke-width='2'
            ></polygon>
          </svg>
          &nbsp;to send the described message to a Ukrainian email address.
        </div>
      </div>
    </>
  )
}

export default Intro
