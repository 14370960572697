import { useState, useRef } from 'react'
import anime from 'animejs/lib/anime'

const Grid = ({ d, theme }) => {
  const ref = useRef()

  return (
    <div
      style={{
        backgroundColor: theme.ylw0,
        boxShadow: '4px 4px 20px #d9d4c9',
      }}
      ref={ref}
    >
      <div>{d.quote}</div>
      <svg
        height='24'
        viewBox='0 0 24 24'
        width='24'
        onClick={() => {
          anime({
            targets: ref.current,
            translateX: '100vw',
            duration: 1600,
          })
        }}
      >
        <line
          fill='none'
          stroke={theme.blu2}
          stroke-linejoin='round'
          stroke-width='2'
          x1='22'
          x2='9.218'
          y1='3'
          y2='10.083'
        ></line>
        <polygon
          fill='none'
          points='11.698 20.334 22 3.001 2 3.001 9.218 10.084 11.698 20.334'
          stroke={theme.blu2}
          stroke-linejoin='round'
          stroke-width='2'
        ></polygon>
      </svg>
    </div>
  )
}

export default Grid
