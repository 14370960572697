import { useState, useRef } from 'react'
import anime from 'animejs/lib/anime'

import Card from './card'

const Grid = ({ theme }) => {
  const [quotes, setQuotes] = useState([
    {
      quote: 'Holding you close in my thoughts and hoping you are doing OK.',
    },
    {
      quote:
        'Sending healing prayers and comforting hugs. I am so sorry for your loss.',
    },
    {
      quote:
        'My heart goes out to you at this difficult time. Wishing you the best and know you have my full support in every moment.',
    },
    {
      quote:
        'I am thinking of you and your family and sending caring thoughts your way to support you through this.',
    },
    {
      quote:
        'It is often hard to find the right words at a time like this. May you find peace, comfort, and all the love you need in the days to come.',
    },
    {
      quote:
        'My sincerest condolences for you at this time. You have my deepest sympathy and unwavering support.',
    },
    {
      quote:
        'I am deeply sorry to hear about the passing of your loved one. I know they meant the world to you and to so many others. Their memory will forever live on in our hearts.',
    },
    {
      quote:
        'We want to express our sympathy and let you know that our thoughts are with you',
    },
    {
      quote: 'Keeping you in my thoughts and prayers.',
    },
  ])

  const ref = useRef()

  return (
    <div className='grid-container'>
      {quotes.map((d) => (
        <Card d={d} theme={theme} />
      ))}
    </div>
  )
}

export default Grid
